import React, { useEffect, useState } from 'react';
import { Card, Typography, Tooltip } from 'antd';
import { LinkedinFilled } from '@ant-design/icons';
import styled from 'styled-components';
// models
import { CompanyUserContact } from '@models/api/contacts';
// utils
import { convertToURL } from '@optx/utils/url';
import { getBackgroundType } from '@utils/images';
// components
import CopyButton from '@optx/components/common/CopyButton';
// styles
import { defaultAvatarImage } from '@optx/constants/images';

const StyledWrapper = styled.div`
  .transparent-logo {
    .ant-card-cover img {
      background-color: #ffffff;
    }
  }
  .white-transparent-logo {
    .ant-card-cover img {
      background-color: #90a0b7;
    }
  }
`;

const { Meta } = Card;
const { Paragraph } = Typography;

interface PrimaryContactCardProps {
  primaryContact: CompanyUserContact;
  logo: string | null;
}

const PrimaryContactCard: React.FC<PrimaryContactCardProps> = ({ primaryContact, logo }) => {
  const {
    full_name: fullName,
    company_name: companyName,
    title,
    display_location: displayLocation,
    linkedin,
  } = primaryContact;

  const [transparentBgClass, setTransparentBgClass] = useState<string>('');

  useEffect(() => {
    if (logo) {
      getBackgroundType(logo)
        .then(result => {
          if (result === 'transparent') {
            setTransparentBgClass('transparent-logo');
          } else if (result === 'white-transparent') {
            setTransparentBgClass('white-transparent-logo');
          } else {
            setTransparentBgClass('');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [logo]);

  return (
    <StyledWrapper>
      <Card
        bordered={false}
        className={transparentBgClass}
        cover={<img alt="company logo" src={logo || defaultAvatarImage} crossOrigin="anonymous" />}
      >
        <Meta
          title={fullName}
          description={[
            <Paragraph key="contact-title" className="contact-title">
              {`${title} at ${companyName}`}
            </Paragraph>,
            <Paragraph key="contact-location" className="contact-location">
              {displayLocation}
            </Paragraph>,
            <Paragraph key="contact-social-media" className="contact-social-media">
              {linkedin && (
                <Tooltip title={<CopyButton text={linkedin} />}>
                  <a
                    href={convertToURL(linkedin)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialmedia"
                  >
                    <LinkedinFilled style={{ fontSize: 24, color: '#0077b7' }} />
                  </a>
                </Tooltip>
              )}
            </Paragraph>,
          ]}
        />
      </Card>
    </StyledWrapper>
  );
};

export default PrimaryContactCard;
